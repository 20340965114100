import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/client'

const CREATE_QUERY = gql`
    query createAuditScenarioModalQuery {
        purposes {
            id
            externalId
            name
        }
    }
`

export function useCreateAuditScenarioModalQuery(options) {
    const {
        data = { audit: {} },
        error,
        loading: isFetching,
        refetch,
    } = useQuery(CREATE_QUERY, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        ...options,
    })
    return {
        error,
        purposes: data.purposes || [],
        isFetching,
        refetch,
    }
}

const CREATE_MUTATION = gql`
    mutation createAuditScenario(
        $auditId: ID!
        $input: CreateAuditScenarioInput!
    ) {
        createAuditScenario(auditId: $auditId, input: $input) {
            auditScenario {
                id
            }
        }
    }
`

export function useCreateAuditScenario(options) {
    return useMutation(CREATE_MUTATION, options)
}

const UPDATE_QUERY = gql`
    query updateAuditScenarioModalQuery($id: ID!) {
        auditScenario(id: $id) {
            id
            name
            deviceOs
            type
            consentType
            purposes {
                id
                externalId
                name
            }
            actions
        }
        purposes {
            id
            externalId
            name
        }
    }
`

export function useUpdateAuditScenarioModalQuery(auditScenarioId, options) {
    const variables = { id: auditScenarioId }
    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
    } = useQuery(UPDATE_QUERY, {
        variables,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-only',
        ...options,
    })
    return {
        error,
        auditScenario: data.auditScenario,
        purposes: data.purposes || [],
        isFetching,
        refetch,
    }
}

const UPDATE_MUTATION = gql`
    mutation updateAuditScenario($id: ID!, $input: UpdateAuditScenarioInput!) {
        updateAuditScenario(id: $id, input: $input) {
            auditScenario {
                id
            }
        }
    }
`

export function useUpdateAuditScenario(options) {
    return useMutation(UPDATE_MUTATION, options)
}
