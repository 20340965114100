import React from 'react'
import { Trans } from '@lingui/macro'
import useNotifications from '../../hooks/useNotifications'
import CreateAuditScenarioModal from './CreateAuditScenarioModal'
import useCreateAuditScenarioFormState from './useCreateAuditScenarioFormState'
import {
    useCreateAuditScenario,
    useCreateAuditScenarioModalQuery,
} from './useAuditScenarioModalQueries'

const CreateAuditScenarioModalWithState = ({
    audit,
    onDismiss,
    onCompleted,
    onError,
}) => {
    const { purposes } = useCreateAuditScenarioModalQuery()
    const [createAuditScenario] = useCreateAuditScenario({
        onCompleted,
        onError,
    })
    const formState = useCreateAuditScenarioFormState(purposes)
    const { dispatchSuccess, dispatchGraphqlError } = useNotifications()
    return (
        <CreateAuditScenarioModal
            purposes={purposes}
            formState={formState}
            onDismiss={() => {
                formState.resetForm()
                onDismiss()
            }}
            onSubmit={async () => {
                if (formState.validate()) {
                    try {
                        const {
                            name,
                            deviceOs,
                            consentType,
                            purposeIds,
                            actions,
                        } = formState.values
                        const input = {
                            name,
                            deviceOs,
                            actions,
                            consentType,
                            purposeIds,
                        }
                        await createAuditScenario({
                            variables: { auditId: audit.id, input },
                        })
                        formState.resetForm()
                        onDismiss()
                        dispatchSuccess({
                            message: (
                                <Trans>
                                    New audit scenario {name} created!
                                </Trans>
                            ),
                        })
                    } catch (error) {
                        dispatchGraphqlError(error)
                    }
                }
            }}
        />
    )
}

export default CreateAuditScenarioModalWithState
