/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import Button from './Button'
import Modal from './Modal'
import ModalTitle from './ModalTitle'
import FormGroup from './FormGroup'
import FormInput from './FormInput'
import useUpdateVendor from './useUpdateVendor'
import VendorTypeSelect from './VendorTypeSelect'
import ModalFooter from './ModalFooter'
import FormTextarea from './FormTextarea'
import StaffFeedbackButtons from './StaffFeedbackButtons'

const UpdateVendorModal = ({ vendorId, onCompleted, onError, onDismiss }) => {
    const {
        feedbackId,
        handleSubmit,
        handleGenerate,
        handleFeedbackCompleted,
        formState,
        isGenerating,
        isUpdating,
    } = useUpdateVendor(vendorId, {
        onCompleted,
        onError,
    })
    const { values, getErrorMessages, getNativeInputProps, handleChange } =
        formState
    return (
        <Modal onDismiss={onDismiss} className="max-w-xl">
            <ModalTitle
                title={<Trans>Update vendor</Trans>}
                description={
                    <Trans>Fill in all details about the vendor below.</Trans>
                }
                onClose={onDismiss}
            />
            <FormGroup label={<Trans>External Id</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('externalId')}
                    {...getNativeInputProps('externalId')}
                />
            </FormGroup>
            <FormGroup label={<Trans>Name</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('name')}
                    {...getNativeInputProps('name')}
                />
            </FormGroup>
            <FormGroup label={<Trans>Description</Trans>} className="mb-4">
                <FormTextarea
                    rows={4}
                    errors={getErrorMessages('description')}
                    {...getNativeInputProps('description')}
                />
            </FormGroup>
            <FormGroup label={<Trans>Type</Trans>} htmlFor="type">
                <VendorTypeSelect
                    value={values.types}
                    errors={getErrorMessages('types')}
                    onChange={(types) => handleChange('types', types)}
                />
            </FormGroup>
            <FormGroup label={<Trans>Root Domain</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('domain')}
                    {...getNativeInputProps('domain')}
                />
            </FormGroup>
            <FormGroup label={<Trans>Website</Trans>} className="mb-4">
                <FormInput
                    errors={getErrorMessages('websiteUrl')}
                    {...getNativeInputProps('websiteUrl')}
                />
            </FormGroup>
            <FormGroup
                label={<Trans>Privacy Policy URL</Trans>}
                className="mb-4"
            >
                <FormInput
                    errors={getErrorMessages('privacyPolicyUrl')}
                    {...getNativeInputProps('privacyPolicyUrl')}
                />
            </FormGroup>
            <ModalFooter
                leftActions={
                    <>
                        {!feedbackId && (
                            <Button
                                type="white"
                                iconBeforeProps={{
                                    size: 'sm',
                                    name: 'ai-stars',
                                    color: 'primary',
                                }}
                                onClick={() => handleGenerate()}
                                loading={isGenerating}
                            >
                                <Trans>Autofill using AI</Trans>
                            </Button>
                        )}
                        {feedbackId && (
                            <StaffFeedbackButtons
                                feedbackId={feedbackId}
                                onCompleted={handleFeedbackCompleted}
                            />
                        )}
                    </>
                }
            >
                <Button
                    type="white"
                    onClick={() => {
                        formState.resetForm()
                        onDismiss()
                    }}
                >
                    <Trans>Cancel</Trans>
                </Button>
                <Button
                    onClick={() => {
                        handleSubmit()
                        onDismiss()
                    }}
                    loading={isUpdating}
                >
                    <Trans>Update</Trans>
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default UpdateVendorModal
