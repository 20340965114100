import React, { useState } from 'react'
import useSearch from '../../hooks/useSearch'
import useCloseDrawerOnLeave from '../../hooks/useCloseDrawerOnLeave'
import StaffDataIdentifiersPage from './StaffDataIdentifiersPage'
import useStaffDataIdentifiersPageQuery from './useStaffDataIdentifiersPageQuery'
import useRefetch from '../../hooks/useRefetch'
import CreateDataIdentifierModalWithState from './CreateDataIdentifierModalWithState' // Importeer de modal

export default function StaffDataIdentifiersPageWithState() {
    useCloseDrawerOnLeave()
    const [search, handleSearch] = useSearch()
    const [isModalOpen, setModalOpen] = useState(false)
    const {
        dataIdentifiers,
        error,
        refetch,
        isFetching,
        isFetchingMore,
        handleFetchMore,
        hasMore,
    } = useStaffDataIdentifiersPageQuery({
        search,
    })
    useRefetch(refetch)
    return (
        <>
            {isModalOpen && (
                <CreateDataIdentifierModalWithState
                    onDismiss={() => setModalOpen(false)}
                    onCompleted={() => setModalOpen(false)}
                />
            )}
            <StaffDataIdentifiersPage
                search={search}
                dataIdentifiers={dataIdentifiers}
                isFetchingMore={isFetchingMore}
                hasMoreRecords={hasMore}
                onFetchMore={handleFetchMore}
                onSearch={handleSearch}
                isFetching={isFetching}
                error={error}
                onCreateDataIdentifier={() => setModalOpen(true)}
            />
        </>
    )
}
