import React from 'react'
import { Trans } from '@lingui/macro'
import FormGroup from '../../utils/FormGroup'
import FormInput from '../../utils/FormInput'
import FormSelect from '../../utils/FormSelect'
import ButtonLink from '../../utils/ButtonLink' // Gebruik voor de nieuwe "Add pattern"-stijl
import {
    DATA_IDENTIFIER_TYPE_SENSITIVE_DATA,
    DATA_IDENTIFIER_TYPE_ID,
    DATA_IDENTIFIER_TYPE_PII,
    DATA_IDENTIFIER_TYPE_OTHER,
    DATA_SENSITIVITY_HIGH,
    DATA_SENSITIVITY_LOW,
    DATA_SENSITIVITY_MEDIUM,
} from '@clew/shared/src/constants'

export default function CreateDataIdentifierForm({ formState }) {
    return (
        <form>
            {/* Name */}
            <FormGroup label={<Trans>Name</Trans>} htmlFor="name" isHorizontal>
                <FormInput
                    id="name"
                    className="max-w-lg"
                    errors={formState.getErrorMessages('name')}
                    {...formState.getNativeInputProps('name')}
                />
            </FormGroup>

            {/* Sensitivity */}
            <FormGroup
                label={<Trans>Sensitivity</Trans>}
                isHorizontal
                htmlFor="sensitivity"
            >
                <FormSelect
                    id="sensitivity"
                    className="max-w-md"
                    errors={formState.getErrorMessages('sensitivity')}
                    {...formState.getNativeInputProps('sensitivity')}
                >
                    <option value="" disabled>
                        <Trans>Select sensitivity</Trans>
                    </option>
                    <option value={DATA_SENSITIVITY_LOW}>
                        <Trans>Low</Trans>
                    </option>
                    <option value={DATA_SENSITIVITY_MEDIUM}>
                        <Trans>Medium</Trans>
                    </option>
                    <option value={DATA_SENSITIVITY_HIGH}>
                        <Trans>High</Trans>
                    </option>
                </FormSelect>
            </FormGroup>

            {/* Type */}
            <FormGroup isHorizontal label={<Trans>Type</Trans>} htmlFor="type">
                <FormSelect
                    id="type"
                    className="max-w-md"
                    errors={formState.getErrorMessages('type')}
                    {...formState.getNativeInputProps('type')}
                >
                    <option value="" disabled>
                        <Trans>Select type</Trans>
                    </option>
                    <option value={DATA_IDENTIFIER_TYPE_PII}>
                        <Trans>PII</Trans>
                    </option>
                    <option value={DATA_IDENTIFIER_TYPE_ID}>
                        <Trans>ID</Trans>
                    </option>
                    <option value={DATA_IDENTIFIER_TYPE_SENSITIVE_DATA}>
                        <Trans>Sensitive data</Trans>
                    </option>
                    <option value={DATA_IDENTIFIER_TYPE_OTHER}>
                        <Trans>Other</Trans>
                    </option>
                </FormSelect>
            </FormGroup>

            {/* Patterns Section */}
            <FormGroup
                isHorizontal
                label={<Trans>Patterns</Trans>}
                htmlFor="patterns"
            >
                {formState.values.patterns?.length > 0 &&
                    formState.values.patterns.map((pattern, index) => (
                        <div
                            key={index}
                            className="mb-4 grid w-full grid-cols-8 gap-4"
                        >
                            <FormInput
                                id={`pattern-${index}`}
                                type="text"
                                className="col-span-6"
                                value={pattern}
                                onChange={(e) => {
                                    const updatedPatterns = [
                                        ...formState.values.patterns,
                                    ]
                                    updatedPatterns[index] = e.target.value
                                    formState.setValues({
                                        ...formState.values,
                                        patterns: updatedPatterns,
                                    })
                                }}
                            />
                            <div className="col-span-2 flex items-center">
                                <ButtonLink
                                    type="danger"
                                    onClick={() =>
                                        formState.removePatternField(index)
                                    }
                                    disabled={
                                        formState.values.patterns.length === 1
                                    }
                                >
                                    <Trans>Remove</Trans>
                                </ButtonLink>
                            </div>
                        </div>
                    ))}
                <div>
                    <ButtonLink
                        onClick={formState.addPatternField}
                        type="primary"
                    >
                        <Trans>+ Add another pattern</Trans>
                    </ButtonLink>
                </div>
            </FormGroup>

            {/* Examples */}
            <FormGroup
                isHorizontal
                label={<Trans>Examples</Trans>}
                htmlFor="examples"
            >
                <FormInput
                    id="examples"
                    className="max-w-lg"
                    errors={formState.getErrorMessages('examples')}
                    {...formState.getNativeInputProps('examples')}
                />
            </FormGroup>
        </form>
    )
}
