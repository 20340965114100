import React from 'react'
import { Trans } from '@lingui/macro'
import { atob, isBase64Encoded } from '../../../utils/index'
import Tag from '../../utils/Tag'
import DrawerDetailsItem, {
    DrawerDetailsItemText,
} from '../../utils/DrawerDetailsItem'
import RequestParamTableRow from '../../utils/RequestParamTableRow'
import Avatar from '../../utils/Avatar'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import Tooltip from '../../utils/Tooltip'

const formatPostData = (postData) => {
    try {
        const unescapedInput = postData.replace(/\\"/g, '"')
        const json = JSON.parse(unescapedInput)
        return JSON.stringify(json, null, 2)
    } catch (e) {
        return postData
    }
}

export default function ScanRequestDetailsDrawerTabDetails({ scanRequest }) {
    const {
        method,
        audit,
        scan,
        resourceType,
        rootDomain,
        scanPage,
        initiator,
        postData,
        responseContent,
        tracker,
    } = scanRequest

    const url = new URL(scanRequest.url)
    const { pathname, searchParams } = url
    const decodedSearchParams = Array.from(url.searchParams.entries()).map(
        ([key, value]) => [key, isBase64Encoded(value) ? atob(value) : value]
    )
    // const requestSearchParams = Array.from(searchParams.entries()).map(
    //     ([key, value]) => ({
    //         request: key,
    //         value,
    //     })
    // )
    return (
        <>
            {audit && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Audit</Trans>}
                >
                    <div className="flex items-center gap-2">
                        <Avatar
                            size="xs"
                            iconName="clock"
                            src={audit.imageUrl}
                        />
                        {audit.name}
                    </div>
                </DrawerDetailsItemText>
            )}

            {tracker && tracker.vendor && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Vendor</Trans>}
                >
                    <div className="flex items-center gap-2">
                        <Avatar
                            size="xs"
                            iconName="building-07"
                            src={tracker.vendor.imageUrl}
                        />
                        {tracker.vendor.name}
                    </div>
                </DrawerDetailsItemText>
            )}

            {scan && (
                <DrawerDetailsItemText isHorizontal label={<Trans>Scan</Trans>}>
                    {scan.consentProfile && (
                        <Tag type="primary">{`${
                            scan.startedAt.split('T')[0]
                        } - ${scan.consentProfile.name}`}</Tag>
                    )}
                    {scan.auditProfile && (
                        <Tag type="primary">{`${
                            scan.startedAt.split('T')[0]
                        } - ${scan.auditProfile.name}`}</Tag>
                    )}
                    {scan.consentSettings?.name && (
                        <Tag type="primary">{`${
                            scan.startedAt.split('T')[0]
                        } - ${scan.consentSettings.name}`}</Tag>
                    )}
                </DrawerDetailsItemText>
            )}
            {scanPage && (
                <DrawerDetailsItemText isHorizontal label={<Trans>Page</Trans>}>
                    {scanPage ? new URL(scanPage?.url).pathname : 'N/A'}
                </DrawerDetailsItemText>
            )}
            {method && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Method</Trans>}
                >
                    {method}
                </DrawerDetailsItemText>
            )}
            {resourceType && (
                <DrawerDetailsItemText isHorizontal label={<Trans>Type</Trans>}>
                    {resourceType}
                </DrawerDetailsItemText>
            )}
            {initiator && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Initiator Type</Trans>}
                >
                    <Tag type="primary">{initiator.type}</Tag>
                </DrawerDetailsItemText>
            )}
            {initiator && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Initiator Path</Trans>}
                >
                    <Tooltip event="hover" content={initiator.url}>
                        {initiator.url}
                    </Tooltip>
                </DrawerDetailsItemText>
            )}
            {rootDomain && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Domain</Trans>}
                >
                    {rootDomain}
                </DrawerDetailsItemText>
            )}
            {pathname && (
                <DrawerDetailsItemText isHorizontal label={<Trans>Path</Trans>}>
                    {pathname}
                </DrawerDetailsItemText>
            )}
            {url && (
                <DrawerDetailsItemText
                    isHorizontal
                    label={<Trans>Full URL</Trans>}
                >
                    <Tooltip
                        event="hover"
                        position="bottom-end"
                        content={
                            <div className="max-w-[400px] overflow-hidden break-words">
                                {scanRequest.url}
                            </div>
                        }
                        contentClassName="max-w-[400px]"
                    >
                        {scanRequest.url}
                    </Tooltip>
                </DrawerDetailsItemText>
            )}
            {decodedSearchParams.length > 0 && (
                <DrawerDetailsItem>
                    <h3 className="mb-4 text-sm font-medium text-slate-900">
                        Request parameters
                    </h3>
                    <Table hasMinWidth={false} isRounded hasHeader={false}>
                        <TableBody>
                            {decodedSearchParams.map(([key, value]) => (
                                <RequestParamTableRow
                                    paramName={key}
                                    paramValue={value}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </DrawerDetailsItem>
            )}
            {postData && (
                <DrawerDetailsItem label={<Trans>Post data</Trans>}>
                    <pre className="inline-flex w-full items-center space-x-4 rounded-lg bg-gray-800 p-4 pl-6 text-left text-xs text-white">
                        {formatPostData(postData)}
                    </pre>
                </DrawerDetailsItem>
            )}
            {responseContent && (
                <DrawerDetailsItem label={<Trans>Response body</Trans>}>
                    <pre className="inline-flex items-center space-x-4 rounded-lg bg-gray-800 p-4 pl-6 text-left text-xs text-white">
                        {formatPostData(responseContent)}
                    </pre>
                </DrawerDetailsItem>
            )}
        </>
    )
}
