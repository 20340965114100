import React, { useState } from 'react'
import * as constants from '../../../constants'
import useAppState from '../../hooks/useAppState'
import AuditSettingsPageAuditScenarioTableRowActionButtons from './AuditSettingsPageAuditScenarioTableRowActionButtons'
import DeleteAuditScenarioModalWithState from './DeleteAuditScenarioModalWithState'
import UpdateAuditScenarioModalWithState from './UpdateAuditScenarioModalWithState'

const { USER_ROLE_OWNER: OWNER } = constants

const AuditSettingsPageAuditScenarioTableRowActionButtonWithState = ({
    auditScenario,
    onDeleteAuditScenarioCompleted,
    onUpdateAuditScenarioCompleted,
}) => {
    const { currentUser } = useAppState()
    const [isDeleteAuditScenarioModalOpen, setDeleteAuditScenarioModalOpen] =
        useState(false)
    const [isUpdateAuditScenarioModalOpen, setUpdateAuditScenarioModalOpen] =
        useState(false)
    return (
        <>
            {isDeleteAuditScenarioModalOpen && (
                <DeleteAuditScenarioModalWithState
                    auditScenario={auditScenario}
                    onDismiss={() => setDeleteAuditScenarioModalOpen(false)}
                    onCompleted={onDeleteAuditScenarioCompleted}
                />
            )}
            {isUpdateAuditScenarioModalOpen && (
                <UpdateAuditScenarioModalWithState
                    auditScenario={auditScenario}
                    onDismiss={() => setUpdateAuditScenarioModalOpen(false)}
                    onCompleted={onUpdateAuditScenarioCompleted}
                />
            )}
            <AuditSettingsPageAuditScenarioTableRowActionButtons
                auditScenario={auditScenario}
                canDeleteAuditScenario={currentUser.role === OWNER}
                canUpdateAuditScenario={currentUser.role === OWNER}
                onDeleteAuditScenario={() =>
                    setDeleteAuditScenarioModalOpen(true)
                }
                onUpdateAuditScenario={() =>
                    setUpdateAuditScenarioModalOpen(true)
                }
            />
        </>
    )
}

export default AuditSettingsPageAuditScenarioTableRowActionButtonWithState
