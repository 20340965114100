import React from 'react'
import { Trans } from '@lingui/macro'
import useFormState, { createFormValidation } from 'use-form-state'
import { DEVICE_OS_ANDROID } from '@clew/shared/src/constants'
import { isRequired } from '../../../utils/validation'

const validation = createFormValidation([
    {
        path: 'name',
        validate: isRequired,
        message: <Trans>Choose a name for this scenario.</Trans>,
    },
    {
        path: 'deviceOs',
        validate: isRequired,
        message: (
            <Trans>Choose a device os to which this scenario applies.</Trans>
        ),
    },
    {
        path: 'consentType',
        validate: isRequired,
        message: (
            <Trans>
                Choose a consent type that you would like to validate.
            </Trans>
        ),
    },
    {
        path: 'actions',
        validate: isRequired,
        message: <Trans>Provide a set of actions to follow</Trans>,
    },
])

export default function useUpdateAuditScenarioFormState(
    allPurposes,
    initialData
) {
    const purposeIds = initialData?.purposes?.map(({ id }) => id) || []
    const formState = useFormState(
        {
            name: initialData?.name || '',
            deviceOs: initialData?.deviceOs || DEVICE_OS_ANDROID,
            consentType: initialData?.consentType || 'NO_CONSENT',
            purposeIds,
            actions: initialData?.actions || '',
        },
        {
            validation,
        }
    )
    formState.handleNoConsent = () => {
        formState.handleChange('consentType', 'NO_CONSENT')
        formState.handleChange(
            'purposeIds',
            allPurposes
                .filter(({ externalId }) => externalId === 'essential')
                .map(({ id }) => id)
        )
    }
    formState.handleRejectAll = () => {
        formState.handleChange('consentType', 'REJECT_ALL')
        formState.handleChange(
            'purposeIds',
            allPurposes
                .filter(({ externalId }) => externalId === 'essential')
                .map(({ id }) => id)
        )
    }
    formState.handleAcceptAll = () => {
        formState.handleChange('consentType', 'ACCEPT_ALL')
        formState.handleChange(
            'purposeIds',
            allPurposes.map(({ id }) => id)
        )
    }
    formState.handleCustom = () => {
        formState.handleChange('consentType', 'CUSTOM')
        formState.handleChange('purposeIds', [])
    }
    formState.handleChangePurpose = (purposeId) => {
        formState.handleChange('purposeIds', (purposeIds) => {
            if (purposeIds.includes(purposeId)) {
                return purposeIds.filter((id) => id !== purposeId)
            }
            return [...purposeIds, purposeId]
        })
    }
    return formState
}
