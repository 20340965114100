import { gql, useMutation } from '@apollo/client'

export const CREATE_DATA_IDENTIFIER_MUTATION = gql`
    mutation staffCreateDataIdentifier($input: CreateDataIdentifierInput!) {
        staffCreateDataIdentifier(input: $input) {
            dataIdentifier {
                id
                name
                sensitivity
                type
                patterns
            }
        }
    }
`

export default function useCreateDataIdentifierMutation(options) {
    return useMutation(CREATE_DATA_IDENTIFIER_MUTATION, options)
}
